<template>
  <div>
    <div class="text-center mt-3 mb-3" >
      <b-card >
        <b-container>
          <p class="TextBlack32 mt-4">
            {{ $t("teacher") }}
          </p>
          <b-row class="mt-4 pt-4">
            <b-col>
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header class="p-1 famouscard1" role="tab">
                  <b-button
                    block
                    @click="ischange = !ischange"
                    variant="white"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                    >
                      {{ $t("teacher_1") }}
                    </p>
                    <b-icon
                      class="IconStyle "
                      :icon="ischange ? 'chevron-up' : 'chevron-down'"
                    ></b-icon
                  ></b-button>
                </b-card-header>
                <b-collapse
                  :visible="ischange"
                  id="accordion-1"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("teacher_1_1") }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header
                  header-tag="header"
                  class="p-1 famouscard1"
                  role="tab"
                >
                  <b-button
                    block
                    @click="ischange2 = !ischange2"
                    variant="white"
                    class="text"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto; position:absolute"
                    >
                      {{ $t("teacher_2") }}
                    </p>
                    <b-icon
                      class="IconStyle"
                      :icon="ischange2 ? 'chevron-up' : 'chevron-down'"
                    ></b-icon>
                  </b-button>
                </b-card-header>
                <b-collapse
                  :visible="ischange2"
                  id="accordion-2"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("teacher_2_1") }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header
                  header-tag="header"
                  class="p-1 famouscard1"
                  role="tab"
                >
                  <b-button
                    block
                    @click="ischange3 = !ischange3"
                    variant="white"
                    class="text"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto; position:absolute"
                    >
                      {{ $t("teacher_3") }}
                    </p>
                    <b-icon
                      class="IconStyle"
                      :icon="ischange3 ? 'chevron-up' : 'chevron-down'"
                    ></b-icon>
                  </b-button>
                </b-card-header>
                <b-collapse
                  :visible="ischange3"
                  id="accordion-3"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("teacher_3_1") }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header
                  header-tag="header"
                  class="p-1 famouscard1"
                  role="tab"
                >
                  <b-button
                    block
                    @click="ischange4 = !ischange4"
                    variant="white"
                    class="text"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto; position:absolute"
                    >
                      {{ $t("teacher_4") }}
                    </p>
                    <b-icon
                      class="IconStyle"
                      :icon="ischange4 ? 'chevron-up' : 'chevron-down'"
                    ></b-icon
                  ></b-button>
                </b-card-header>
                <b-collapse
                  :visible="ischange4"
                  id="accordion-4"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("teacher_4_1") }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header
                  header-tag="header"
                  class="p-1 famouscard1"
                  role="tab"
                >
                  <b-button
                    block
                    @click="ischange5 = !ischange5"
                    variant="white"
                    class="text"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                    >
                      {{ $t("teacher_5") }}
                    </p>
                    <b-icon
                      class="IconStyle"
                      :icon="ischange5 ? 'chevron-up' : 'chevron-down'"
                    ></b-icon
                  ></b-button>
                </b-card-header>
                <b-collapse
                  :visible="ischange5"
                  id="accordion-5"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("teacher_5_1") }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header
                  header-tag="header"
                  class="p-1 famouscard1"
                  role="tab"
                >
                  <b-button
                    block
                    @click="ischange6 = !ischange6"
                    variant="white"
                    class="text"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto; position:absolute"
                    >
                      {{ $t("teacher_6") }}
                    </p>
                    <b-icon
                      class="IconStyle"
                      :icon="ischange6 ? 'chevron-up' : 'chevron-down'"
                    ></b-icon
                  ></b-button>
                </b-card-header>
                <b-collapse
                  :visible="ischange6"
                  id="accordion-6"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("teacher_6_1") }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header
                  header-tag="header"
                  class="p-1 famouscard1"
                  role="tab"
                >
                  <b-button
                    block
                    @click="ischange7 = !ischange7"
                    variant="white"
                    class="text"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                    >
                      {{ $t("teacher_7") }}
                    </p>
                    <b-icon
                      class="IconStyle"
                      :icon="ischange7 ? 'chevron-up' : 'chevron-down'"
                    ></b-icon
                  ></b-button>
                </b-card-header>
                <b-collapse
                  :visible="ischange7"
                  id="accordion-7"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("teacher_7_1") }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header
                  header-tag="header"
                  class="p-1 famouscard1"
                  role="tab"
                >
                  <b-button
                    block
                    @click="ischange8 = !ischange8"
                    variant="white"
                    class="text"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                    >
                      {{
                        $t("teacher_8")
                      }}
                    </p>
                    <b-icon
                      class="IconStyle"
                      :icon="ischange8 ? 'chevron-up' : 'chevron-down'"
                    ></b-icon>
                  </b-button>
                </b-card-header>
                <b-collapse
                  :visible="ischange8"
                  id="accordion-8"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("teacher_8_1") }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header
                  header-tag="header"
                  class="p-1 famouscard1"
                  role="tab"
                >
                  <b-button
                    block
                    @click="ischange9 = !ischange9"
                    variant="white"
                    class="text"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                    >
                      {{
                        $t("teacher_9")
                      }}
                    </p>
                    <b-icon
                      class="IconStyle"
                      :icon="ischange9 ? 'chevron-up' : 'chevron-down'"
                    ></b-icon
                  ></b-button>
                </b-card-header>
                <b-collapse
                  :visible="ischange9"
                  id="accordion-9"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("teacher_9_1") }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header
                  header-tag="header"
                  class="p-1 famouscard1"
                  role="tab"
                >
                  <b-button
                    block
                    @click="ischange10 = !ischange10"
                    variant="white"
                    class="text"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                    >
                      {{ $t("teacher_10") }}
                    </p>
                    <b-icon
                      class="IconStyle"
                      :icon="ischange10 ? 'chevron-up' : 'chevron-down'"
                    ></b-icon
                  ></b-button>
                </b-card-header>
                <b-collapse
                  :visible="ischange10"
                  id="accordion-10"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("teacher_10_1") }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header
                  header-tag="header"
                  class="p-1 famouscard1"
                  role="tab"
                >
                  <b-button
                    block
                    @click="ischange11 = !ischange11"
                    variant="white"
                    class="text"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                    >
                      {{ $t("teacher_11") }}
                    </p>
                    <b-icon
                      class="IconStyle"
                      :icon="ischange11 ? 'chevron-up' : 'chevron-down'"
                    ></b-icon
                  ></b-button>
                </b-card-header>
                <b-collapse
                  :visible="ischange11"
                  id="accordion-11"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("teacher_11_1") }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header
                  header-tag="header"
                  class="p-1 famouscard1"
                  role="tab"
                >
                  <b-button
                    block
                    @click="ischange12 = !ischange12"
                    variant="white"
                    class="text"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                    >
                      {{ $t("teacher_12") }}
                    </p>
                    <b-icon
                      class="IconStyle"
                      :icon="ischange12 ? 'chevron-up' : 'chevron-down'"
                    ></b-icon
                  ></b-button>
                </b-card-header>
                <b-collapse
                  :visible="ischange12"
                  id="accordion-12"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("teacher_12_1") }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header
                  header-tag="header"
                  class="p-1 famouscard1"
                  role="tab"
                >
                  <b-button
                    block
                    @click="ischange13 = !ischange13"
                    variant="white"
                    class="text"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                    >
                      {{ $t("teacher_13") }}
                    </p>
                    <b-icon
                      class="IconStyle"
                      :icon="ischange13 ? 'chevron-up' : 'chevron-down'"
                    ></b-icon
                  ></b-button>
                </b-card-header>
                <b-collapse
                  :visible="ischange13"
                  id="accordion-13"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("teacher_13_1") }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header
                  header-tag="header"
                  class="p-1 famouscard1"
                  role="tab"
                >
                  <b-button
                    block
                    @click="ischange14 = !ischange14"
                    variant="white"
                    class="text"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                    >
                      {{ $t("teacher_14") }}
                    </p>
                    <b-icon
                      class="IconStyle"
                      :icon="ischange14 ? 'chevron-up' : 'chevron-down'"
                    ></b-icon
                  ></b-button>
                </b-card-header>
                <b-collapse
                  :visible="ischange14"
                  id="accordion-14"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("teacher_14_1") }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header
                  header-tag="header"
                  class="p-1 famouscard1"
                  role="tab"
                >
                  <b-button
                    block
                    @click="ischange15 = !ischange15"
                    variant="white"
                    class="text"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                    >
                      {{ $t("teacher_15") }}
                    </p>
                    <b-icon
                      class="IconStyle"
                      :icon="ischange15 ? 'chevron-up' : 'chevron-down'"
                    ></b-icon
                  ></b-button>
                </b-card-header>
                <b-collapse
                  :visible="ischange15"
                  id="accordion-15"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("teacher_15_1") }}
                      </p>
                      <!-- <p
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("IHMXQ_info2") }}
                      </p> -->
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header
                  header-tag="header"
                  class="p-1 famouscard1"
                  role="tab"
                >
                  <b-button
                    block
                    @click="ischange16 = !ischange16"
                    variant="white"
                    class="text"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                    >
                      {{ $t("teacher_16") }}
                    </p>
                    <b-icon
                      class="IconStyle"
                      :icon="ischange16 ? 'chevron-up' : 'chevron-down'"
                    ></b-icon
                  ></b-button>
                </b-card-header>
                <b-collapse
                  :visible="ischange16"
                  id="accordion-16"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("teacher_16_1") }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header
                  header-tag="header"
                  class="p-1 famouscard1"
                  role="tab"
                >
                  <b-button
                    block
                    @click="ischange17 = !ischange17"
                    variant="white"
                    class="text"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                    >
                      {{ $t("teacher_17") }}
                    </p>
                    <b-icon
                      class="IconStyle"
                      :icon="ischange17 ? 'chevron-up' : 'chevron-down'"
                    ></b-icon
                  ></b-button>
                </b-card-header>
                <b-collapse
                  :visible="ischange17"
                  id="accordion-17"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="display:block; margin-top:auto; margin-bottom:auto;text-align:left"
                      >
                        {{ $t("teacher_17_1") }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
        </b-container>
      </b-card>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      ischange: false,
      ischange2: false,
      ischange3: false,
      ischange4: false,
      ischange5: false,
      ischange6: false,
      ischange7: false,
      ischange8: false,
      ischange9: false,
      ischange10: false,
      ischange11: false,
      ischange12: false,
      ischange13: false,
      ischange14: false,
      ischange15: false,
      ischange16: false,
      ischange17: false,
    };
  },
};
</script>
<style></style>
